/* @import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap"); */

@font-face {
  font-family: "Untitled Sans Regular";
  src: url("./fonts/untitled-sans-regular.woff2");
}

html {
  font-family: "Untitled Sans";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Untitled Sans"; /* "Geometric"; */
}


code {
  font-family: "Untitled Sans"; /* source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}

a, a:visited {
  color: "black";
  text-decoration: none; 
}

a:hover {
  text-decoration: underline;
}

.mobile-link:hover {
  text-decoration: none;
}

.paperContainer {
  background-image: url('./img/home-bg.png');
  background-size: cover;
  background-position: center;
  /* width: calc(100vw); */
  /* height: calc(100vh - 64px); */
}

.footer-text {
  font-size: 10px !important;
  color: #b5b8ba
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #b5b8ba;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #b5b8ba;
    border-radius: 6px;
    border: 0px solid #ffffff;
  }